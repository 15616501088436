import React from 'react';
import { useIntl } from 'gatsby-plugin-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelopeSquare, faPhone } from '@fortawesome/free-solid-svg-icons';
import ScrollAnimation from 'react-animate-on-scroll';

import { ContentSection, HeroImage, Layout, Map, Seo, Text } from '../components';
import MainLogo from '../images/logo_main.png';
import '../styles/Home.scss';


const IndexPage = () => {
  const { formatMessage } = useIntl();

  return (
    <Layout>
      <Seo title="NOVA GREEN" />

      <div className="HeroWrapper">
        <div className="HeroImageWrapper">
          <HeroImage />

          <ScrollAnimation animateIn="fadeIn" duration={1.6}>
            {/* <div className="LogoContainer">
              <img src={MainLogo} alt="Logo Nova Green" />
            </div> */}
            <div className="HeroTextContainer">
              <div className="LogoContainer">
                <img src={MainLogo} alt="Logo Nova Green" />
              </div>
              <h3 className="HeroSubtitle">{formatMessage({ id: 'NOVA GREEN to nowe spojrzenie na inwestycje w Odnawialne Źródła Energii.' })}</h3>
            </div>
          </ScrollAnimation>
        </div>
      </div>

      <ContentSection wrapperClassName="About" title={formatMessage({ id: 'O firmie' })} id="about">
        <Text>
          {formatMessage({ id: 'Nova Green realizuje inwestycje wykorzystujące promieniowanie słoneczne do wytwarzania energii elektrycznej. Realizowane przez nas projekty pozwalają na redukcję emisji dwutlenku węgla oraz poprawiają bilans energetyczny na poziomie krajowym, jak również regionalnym.' })}
        </Text>
      </ContentSection>

      <ContentSection wrapperClassName="Team" title={formatMessage({ id: 'Zespół' })} id="team">
        <Text>
          {formatMessage({ id: 'Nasz zespół stanowią pracownicy, którzy posiadają szeroką wiedzę i doświadczenie zdobyte w zakresie wielu obszarów energetyki.' })}
        </Text>
        <Text>
          {formatMessage({ id: 'W skład naszego zespołu wchodzą wyspecjalizowani projektanci, konsultanci z zakresu energetyki oraz konsultanci z zakresu prawa energetycznego oraz handlowego.' })}
        </Text>
      </ContentSection>

      <ContentSection wrapperClassName="Services" title={formatMessage({ id: 'Usługi' })} id="services">
        <Text>
          {formatMessage({ id: 'Realizacja projektu zwykle rozpoczyna się od dialogu z odpowiednimi grupami interesariuszy, takimi jak, np. lokalni mieszkańcy. Każdy grunt zostaje dokładnie przeanalizowany pod względem zgodności inwestycji z obowiązującymi przepisami prawa oraz oceną oddziaływania projektu na środowisko. Staranna analiza terenu w naszej ocenie, stanowi o sukcesie projektu. Następnie podpisywana zostaje umowa dzierżawy gruntu.' })}
        </Text>

        <Text className="ServicesTitle">
          {formatMessage({ id: 'Proces projektowania, w tym:' })}
        </Text>

        <ScrollAnimation animateIn="zoomIn" duration={2}>
          <ul className="List">
            <li>{formatMessage({ id: 'Studium wykonalności' })}</li>
            <li>{formatMessage({ id: 'Dzierżawa gruntów i prawidłowa komunikacja lokalna' })}</li>
          </ul>

          <ul className="List">
            <li>{formatMessage({ id: 'Procesowanie wydawania pozwoleń' })}</li>
          </ul>

          <ul className="List">
            <li>{formatMessage({ id: 'Zabezpieczanie mocy przyłączeniowej ' })}</li>
            <li>{formatMessage({ id: 'Nadzorowanie realizacji i wdrożenia' })}</li>
          </ul>
        </ScrollAnimation>

        {/* <Text className="ServicesTitle">
          {formatMessage({ id: 'Nadzorowanie realizacji i wdrożenia' })}
        </Text> */}
      </ContentSection>

      <ContentSection wrapperClassName="Contact" title={formatMessage({ id: 'Kontakt' })} id="contact">
        <div className="Contact_Maps_Wrapper">
          <div className="MapColumnFirst">
            <h2>{formatMessage({ id: 'NOVA GREEN Sp. z o.o.' })}</h2>
            <h3>{formatMessage({ id: 'ul. Trzy Lipy 3' })}</h3>
            <h3>80-172 Gdańsk</h3>

            <div className="DetailsWrapper">
              <div className="Column">
                <FontAwesomeIcon icon={faEnvelopeSquare} />
                <a href="mailto:biuro@novagreen.pl">biuro@novagreen.pl</a>
              </div>
              <div className="Column">
                <FontAwesomeIcon icon={faPhone} />
                <a href="tel:531 044 589">531 044 589</a>
              </div>
            </div>

            <h4>NIP: 9571126360</h4>
            <h4>REGON: 38685664400000</h4>
            <h4>KRS: 0000856606</h4>
          </div>

          <Map
            src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2325.1295680928465!2d18.59138051587677!3d54.35469138020271!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1spl!2spl!4v1607545951301!5m2!1spl!2spl"
          />
        </div>
      </ContentSection>

    </Layout>
  );
};

export default IndexPage;
